import { computed, Directive, inject, input } from '@angular/core';
import { CacheService } from './auth.service';

@Directive({
  selector: '[paint]',
  standalone: true,
  host: {
    '[style.--custom-color]': 'finalPaint()',
    '[style.--custom-color-secondary]': 'finalPaintSecondary()',
    '[class.painted]': 'true',
  }
})
export class PaintDirective {
  cache = inject(CacheService);

  paint = input.required<string>();
  paintSecondary = input<string>();

  defaultPlan = { identifier: 'NOTFOUND', color_settings: { background: '#181921', foreground: '#ffffff'} };

  plan = computed(() => {
    const paint = this.paint();
    const plans = this.cache.registeredPlans();

    return plans.find(plan => plan.identifier == paint) ?? this.defaultPlan;
  });

  finalPaint = computed(() => {
    const paint = this.paint();
    const plan = this.plan();

    switch(paint){
      case 'blue': case 'primary': return '#0075ff';
      case 'yellow': case 'accent': return '#ffca00';
      case 'red': case 'warn': return '#ff4735';
      case 'black': case 'dark': return '#181921';
      case 'gray': case 'light': return '#8491af';
      case 'darkred': case 'error': return '#ad180d';
      case 'green': case 'success': return '#47ab1b';
      case 'plan': case 'plan-bg': case 'plan-background': return 'var(--plan-background-color)';
      case 'plan-fg': case 'plan-foreground': return 'var(--plan-foreground-color)';
      case `${plan?.identifier}`: case `${plan?.identifier}-bg`: return plan?.color_settings.background;
      case `${plan?.identifier}-fg`: return plan?.color_settings.foreground;
      default: return paint;
    }
  });

  finalPaintSecondary = computed(() => {
    const paint = this.paint();
    const paintSecondary = this.paintSecondary();
    const plan = this.plan();

    switch(paint){
      case 'blue': case 'primary': return '#ffffff';
      case 'yellow': case 'accent': return '#181921';
      case 'red': case 'warn': return '#ffffff';
      case 'black': case 'dark': return '#ffffff';
      case 'gray': case 'light': return '#ffffff';
      case 'plan': case 'plan-bg': case 'plan-background': return 'var(--plan-foreground-color)';
      case 'plan-fg': case 'plan-foreground': return 'var(--plan-background-color)';
      case `${plan?.identifier}`: case `${plan?.identifier}-bg`: return plan?.color_settings.foreground;
      case `${plan?.identifier}-fg`: return plan?.color_settings.background;
      default: return paintSecondary;
    }
  });

}
